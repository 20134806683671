import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';
import { FullPageLoader } from '../../components';
import { RootState } from '../../stores/store';
import { NAV_ROUTES } from '../../constants/navRoutes';
import { messages, titles } from '../../constants/constants';
import emptyDashboard from '../../assets/images/empty-dashboard.svg';
import { getDefaultFilterForUrl } from '../../utils/common';

const Dashboard: FC = () => {
  const navigate = useNavigate();
  const { module: moduleFromUrlParam, submodule: subModule } = useParams<{ module?: string; submodule?: string }>();
  const modules = useSelector((state: RootState) => state?.ModulesData);
  const [isEmptyDashboard, setEmptyDashboard] = useState(false);
  const [embedUrl, setEmbedUrl] = useState<string | null>(null);

  const keycloakUrl = `${process.env.REACT_APP_SUPERSET_DASHBOARD_URL}/login/keycloak?next=`;
  const encodedSupersetUrl = encodeURI(`${process.env.REACT_APP_SUPERSET_DASHBOARD_URL}/superset/dashboard/welcome`);
  const supersetUrl = `${process.env.REACT_APP_SUPERSET_DASHBOARD_URL}/superset/dashboard/${subModule}/?standalone=2&expand_filters=0`;

  // Hook to set dashboardId to load relevant superset dashboard
  useEffect(() => {
    if (subModule) {
      const filterVal = getDefaultFilterForUrl(modules.modules, subModule);
      setEmbedUrl(`${supersetUrl}${filterVal ? `&native_filters=(NATIVE_FILTER-Knb6wV2-t:(__cache:(label:'${filterVal}',validateStatus:!f,value:!('${filterVal}')),extraFormData:(filters:!((col:'game_id',op:IN,val:!('${filterVal}')))),filterState:(label:'${filterVal}',validateStatus:!f,value:!('${filterVal}')),id:NATIVE_FILTER-Knb6wV2-t,ownState:()))` : ""}`);
    }
  }, [subModule, modules]);

  // Hook to redirect to first dashboard in access of user
  // if no permission then redirect to home page
  useEffect(() => {
    const redirectOnEmptyDashboard = () => {
      const redirectModule = modules?.modules?.[0];
      const redirectSubmodule = redirectModule ? (redirectModule?.submodules?.[0] || null) : null;
      if (redirectModule && redirectSubmodule && !moduleFromUrlParam) {
        const urlModuleParam = redirectModule.name.toLowerCase()
          .replace(/ /g, "_")
          .replace(/\(|\)/g, "");
        navigate(`${NAV_ROUTES.DASHBOARD}/${urlModuleParam}/${redirectSubmodule.url}`);
      }
    };

    const redirectModule = modules?.modules?.[0] || null;
    if (!moduleFromUrlParam && redirectModule && redirectModule?.submodules?.length) {
      setTimeout(() => {
        redirectOnEmptyDashboard();
      }, 1000);
    }

    if (modules?.loadCount > 1 && !redirectModule) {
      setEmptyDashboard(true);
    }
  }, [moduleFromUrlParam, modules]);

  const getEmptyDashboardView = () => {
    const getContactUsMessage = () => {
      return (
        <span
          style={{
            fontSize: '0.8rem',
            color: '#6B7280',
            fontWeight: '400',
            maxWidth: '400px'
          }}
        >
          {messages.reachOutAdmin1}
          <a href="mailto:admin@abc.com" style={{ color: '#1D4ED8' }}>
            {messages.administrator}
          </a>
          {messages.reachOutAdmin2}
        </span>
      );
    }

    return (
      <Box
        sx={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center'
        }}
      >
        <img
          src={emptyDashboard}
          alt={titles.PVX_PARTNERS}
          style={{
            width: '150px',
            height: 'auto',
            marginBottom: 20
          }}
        />
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: '500',
            marginBottom: 1
          }}
        >
          {messages.noDashboardAccess}
        </Typography>
        {getContactUsMessage()}
      </Box>
    );
  };

  if (isEmptyDashboard) {
    return getEmptyDashboardView();
  }

  return (
    <>
      {embedUrl ? (
        <iframe
          title="PvX Dashboard View"
          src={`${embedUrl}`}
          style={{
            width: '100%',
            height: '100vh',
            border: 'none'
          }}
        />
      ) : (
        <>
          <iframe
            title="PvX Dashboard Auth"
            src={`${keycloakUrl}${encodedSupersetUrl}`}
            style={{
              display: 'none'
            }}
          />
          <FullPageLoader />
        </>
      )}
    </>
  );
}

export default Dashboard;
