import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuthResponse } from './types';
import UserAuth from './auth';

const MAX_RETRIES = 3;

const authQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_KEYCLOAK_V2_BASE_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect`
});

const noAuthBaseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_DOMAIN}`
});

const baseQueryWithRetryAndReAuth = async (args: any, api: any, extraOptions: any) => {
  if (api.type === "query") {
    let retries = 0;

    while (retries < MAX_RETRIES) {
      try {
        const result = await fetchBaseQuery({
          baseUrl: `${process.env.REACT_APP_API_DOMAIN}`,
          prepareHeaders(headers) {
            const token = UserAuth.accessToken;
            if (token) headers.set('Authorization', `Bearer ${token}`);
            return headers;
          },
        })(args, api, extraOptions);

        if (result.error) {
          if (result.error.status === 401) {
            // Attempt to re-authenticate
            const refresh_token = UserAuth.refreshToken;
            if (refresh_token) {
              const reAuthResult = await api.dispatch(
                reAuthApi.endpoints.authenticate.initiate({ refresh_token })
              ).unwrap();

              if (reAuthResult.access_token) {
                const { access_token, refresh_token } = reAuthResult;
                UserAuth.setTokens({ access_token, refresh_token });

                // Retry the original query with the new token
                continue;
              } else {
                throw new Error('Auth error occurred');
              }
            }
          }
          throw new Error((result.error.data as any)?.message || 'An error occurred');
        }

        return result;
      } catch (err) {
        retries++;
        if (retries >= MAX_RETRIES) {
          if ((err as any)?.data.error === 'invalid_grant') {
            window.location.href = '/auth';
          } else {
            window.location.href = '/server-error';
          }

          return { error: { message: 'Failed after maximum retries', error: err } };
        }
      }
    }
  } else {
    try {
      const result = await fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_DOMAIN}`,
        prepareHeaders(headers) {
          const token = UserAuth.accessToken;
          if (token) headers.set('Authorization', `Bearer ${token}`);
          return headers;
        },
      })(args, api, extraOptions);

      if (result.error) {
        if (result.error.status === 401) {
          // Attempt to re-authenticate
          const refresh_token = UserAuth.refreshToken;
          if (refresh_token) {
            const reAuthResult = await api.dispatch(
              reAuthApi.endpoints.authenticate.initiate({ refresh_token })
            ).unwrap();

            if (reAuthResult.access_token) {
              const { access_token, refresh_token } = reAuthResult;
              UserAuth.setTokens({ access_token, refresh_token });
            } else {
              throw new Error('Auth error occurred');
            }
          }
        }
        throw new Error((result.error.data as any)?.message || 'An error occurred');
      }

      return result;
    } catch (err) {
      return { error: { message: 'Failed to update', error: err } };
    }
  }
};

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: authQuery,
  endpoints: (builder) => ({
    authenticate: builder.mutation<AuthResponse, { code: string }>({
      query: (data) => ({
        url: 'token',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          grant_type: 'authorization_code',
          code: data.code,
          redirect_uri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URI || '',
          client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || '',
          client_secret: process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET || '',
        }).toString(),
      }),
    }),
  }),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true
});

const reAuthApi = createApi({
  reducerPath: 'reAuthApi',
  baseQuery: authQuery,
  endpoints: (builder) => ({
    authenticate: builder.mutation<any, { refresh_token: string }>({
      query: (data) => ({
        url: 'token',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          grant_type: 'refresh_token',
          refresh_token: data.refresh_token,
          client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || '',
          client_secret: process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET || '',
        }).toString(),
      }),
    }),
  }),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true
});

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithRetryAndReAuth as any,
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  tagTypes: [
    'user',
    'userList',
    'modules',
    'companyList',
    'fundingCaluclator',
  ]
});

export const noAuthBaseApi = createApi({
  reducerPath: 'noAuthApi',
  baseQuery: noAuthBaseQuery,
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true
});

export const { useAuthenticateMutation } = authApi;

export default baseApi;
