import React, { FC } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import {
  AuthPage,
  AuthRedirectPage,
  HomePage,
  PageNotFound,
  ErrorPage
} from '../pages';
import { ProtectedLayout } from '../layouts';
import PrivateRoutes from './PrivateRoutes';
import { NAV_ROUTES } from '../constants/navRoutes';

const AppRoutes: FC = () => (
  <Router>
    <Routes>
      <Route path={NAV_ROUTES.AUTH} element={<AuthPage />} />
      <Route path={NAV_ROUTES.AUTH_REDIRECT} element={<AuthRedirectPage />} />
      <Route path={NAV_ROUTES.PAGE_NOT_FOUND} element={<PageNotFound />} />
      <Route path={NAV_ROUTES.HOME} element={<HomePage />} />
      <Route path={NAV_ROUTES.ERROR} element={<ErrorPage />} />
      {/* <Route path={NAV_ROUTES.FUNDING_CALCULATOR} element={<FundingCalculatorLandingPage />} />
      <Route path={NAV_ROUTES.FUNDING_CALCULATOR_V2} element={<FundingCalculatorLandingPageV2 />} />
      <Route path={NAV_ROUTES.FUNDING_CALCULATOR_PLAYGROUND} element={<FundingCalculatorPage isPlayground={true} />} />
      <Route path={NAV_ROUTES.FUNDING_CALCULATOR_DETAILS} element={<FundingCalculatorPage isPlayground={false} />} />
      <Route path={NAV_ROUTES.FUNDING_CALCULATOR_DETAILS_ID} element={<FundingCalculatorPage isPlayground={false} />} /> */}
      <Route path='/' element={<Navigate to={NAV_ROUTES.HOME} />} />
      <Route element={<ProtectedLayout />}>
        <Route path='/*' element={<PrivateRoutes />} />
      </Route>
    </Routes>
  </Router>
);

export default AppRoutes;
