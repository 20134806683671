import React from 'react';
import { Doughnut } from "react-chartjs-2";
import {
    Chart as ChartJs,
    ArcElement
} from "chart.js";
import { Box, Typography } from '@mui/material';
import ErrorChart from '../../../assets/images/error-chart.svg';
import { messages } from '../../../constants/constants';
import DoughnutChartSkelton from './DoughnutChartSkelton';
import { ChartConfigType, ChartProps, DoughnutChartUnitValue } from '../types';
import { getFormattedChartData } from '../utils';
import { getChartConfig } from '../chartConfigs';

ChartJs.register(ArcElement);

const DoughnutChart: React.FC<ChartProps> = ({ type, data, metadata, isLoading, error }) => {
    if ((data && !data?.length) || isLoading) return <DoughnutChartSkelton />;

    if (data) {
        const chartData = getFormattedChartData(type, data);
        const chartConfig: ChartConfigType | null = getChartConfig(type, data, metadata);

        if (chartData && chartConfig) {
            return (
                <Box sx={{ height: '100%', width: '100%' }}>
                    <Doughnut
                        data={{
                            labels: chartData.labels,
                            datasets: chartData.datasets as DoughnutChartUnitValue[]
                        }}
                        options={chartConfig}
                    />
                </Box>
            );
        } else {
            return <DoughnutChartSkelton />;
        }
    } else {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img
                    src={ErrorChart}
                    alt={messages.refreshChart}
                    style={{
                        width: '15rem',
                        height: 'auto',
                        marginBottom: 20
                    }}
                />
                <Typography variant="h6" style={{ textAlign: 'center' }}>
                    {messages.oopsError}
                </Typography>
                <Typography style={{ textAlign: 'center' }}>
                    {messages.refreshChart}
                </Typography>
            </Box>
        );
    }
};

export default DoughnutChart;
