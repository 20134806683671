import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { Box, useTheme } from '@mui/material';
import loader from '../../assets/images/Loader.json';
import { messages } from '../../constants/constants';

const FullPageLoader: React.FC = () => {
    const theme = useTheme();
    return (
        <>
            <Box sx={{
                position: 'absolute',
                zIndex: 9999,
                width: '100%',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Box sx={{ width: '15%' }}>
                    <Player
                        src={loader}
                        className="player"
                        loop
                        autoplay
                    />
                </Box>
                <p>{messages.pleaseWait}</p>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    zIndex: 9988,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: theme.palette.common.black,
                    opacity: '0.3'
                }}
            />
        </>
    );
};

export default FullPageLoader;
