import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Container, useMediaQuery, useTheme } from "@mui/material";
import { ReactComponent as BackArrow } from "../../assets/icons/back_arrow.svg";
import UserDetailsSection from "./components/UserDetailsSection";
import ModuleDetailsSection from "./components/ModuleDetailsSection";
import { NAV_ROUTES } from "../../constants/navRoutes";
import { titles, messages } from "../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores/store";
import useCompany from "../../hooks/api/useCompany";
import useUserModules from "../../hooks/api/useUserModules";
import { UserPayload } from "../../api/company";
import useUserById from "../../hooks/api/useUserById";
import useUpdateUser from "../../hooks/api/useUpdateUser";
import useUserModulesById from "../../hooks/api/useUserModulesById";
import { resetUserData } from "../../stores/SelectedUser/slice";
import { formatModulesToSubmoduleList, getSelectedFilterIds } from "../../utils/common";
import { setToastMessage } from "../../stores/App/slice";
import useDebounce from "../../hooks/useDebounce";
import { resetFilterSelectionData } from "../../stores/Modules/slice";

const UserDetails: React.FC = () => {
  const { id: urlParamUserId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modules = useSelector((state: RootState) => state?.ModulesData);
  const companies = useSelector((state: RootState) => state?.CompanyListData);
  const selectedUser = useSelector((state: RootState) => state?.SelectedUserData);
  const { getUserDetailsById, isLoading: isUserDetailsLoading, isError: isUserDetailsError } = useUserById();
  const { getUserModuleDetailsById, isLoading: isUserModuleDetailsLoading, isError: isUserModuleDetailsError } = useUserModulesById();
  const { getCompanyList } = useCompany();
  const { getUserModules } = useUserModules();
  const { editUser } = useUpdateUser();
  const [isShowModuleEditor, setShowModuleEditor] = useState<Boolean>(false);
  const [updatedSubModuleIds, setUpdatedSubModuleIds] = useState<string[]>([]);
  const [edituserPayload, setEditUserPayload] = useState<UserPayload>(
    {
      id: '',
      email: '',
      username: '',
      userRole: 'VIEWER',
      companyId: '',
      submoduleList: []
    }
  );

  useEffect(() => () => {
    dispatch(resetUserData());
    dispatch(resetFilterSelectionData());
  }, []);

  useEffect(() => {
    if (urlParamUserId) {
      getUserDetailsById(urlParamUserId);
      getUserModuleDetailsById(urlParamUserId);
    }
  }, [urlParamUserId]);

  useEffect(() => {
    if (selectedUser?.user) {
      const userData = {
        ...edituserPayload,
        id: selectedUser.user?.id || '',
        email: selectedUser.user?.email || '',
        username: selectedUser.user?.username || '',
        userRole: selectedUser.user?.userRole || '',
        companyId: selectedUser.user?.companyId || '',
        submoduleList: selectedUser.user?.modules?.length ? formatModulesToSubmoduleList(selectedUser.user.modules) : [],
      };

      setEditUserPayload(userData);
      setShowModuleEditor(selectedUser.user?.userRole === 'VIEWER');
    }
  }, [selectedUser]);

  useEffect(() => {
    if (!companies?.companyList?.length) {
      getCompanyList();
    }
  }, [companies]);

  useEffect(() => {
    if (!modules?.modules?.length || modules?.adminFetchCount === 0) {
      getUserModules(true);
    }
  }, [modules]);

  const updateNewUserData = (field: string, value: any) => {
    if (field === 'userRole') {
      setShowModuleEditor(value === 'VIEWER')
    }
    setEditUserPayload({
      ...edituserPayload,
      [field]: field === 'submoduleList' ? [...value] : value,
    });
  };

  const handleUpdatedSubModuleIds = (id: string) => {
    if (updatedSubModuleIds.includes(id)) {
      setUpdatedSubModuleIds(updatedSubModuleIds.filter((submodId) => submodId !== id));
    } else {
      setUpdatedSubModuleIds([...updatedSubModuleIds, id]);
    }
  }

  const handleUpdateUser = async () => {
    if (
      edituserPayload.companyId
      && edituserPayload.email
    ) {
      edituserPayload.submoduleList.forEach((submodule) => {
        if (updatedSubModuleIds.includes(submodule.submoduleId)) {
          submodule.filterIds = getSelectedFilterIds(modules.modules, submodule.submoduleId);
        }
      });
      const res = await editUser(edituserPayload);
      dispatch(setToastMessage(Boolean(res?.code) ? `${edituserPayload.email} updated !` : `Failed to update ${edituserPayload.email}, please try again !`));
      navigate(NAV_ROUTES.USER_MANAGEMENT);
    }
  };

  const debouncedHandleUpdateUser = useDebounce(handleUpdateUser, 500);

  return (
    <Container
      id='user_details_container'
      sx={{
        height: '100%',
        width: '100%',
        p: 0,
        m: 0,
      }}
    >
      <Box
        sx={{
          height: isMobile ? 43 : 63,
          position: "relative"
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <BackArrow
            style={{ cursor: "pointer" }}
            onClick={() => navigate(NAV_ROUTES.USER_MANAGEMENT)}
          />
          <Box
            sx={{
              fontSize: isMobile ? 28 : 34,
              fontWeight: 700,
              pl: 1
            }}
          >
            {titles.USER_DETAILS}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: `calc(100vh - ${isMobile ? '220' : '170'}px)`,
          overflow: 'auto',
          pb: 3
        }}
      >
        <UserDetailsSection
          user={edituserPayload}
          isUserDetails={true}
          companies={companies.companyList}
          toggleModuleSection={(val: Boolean) => { setShowModuleEditor(val) }}
          onUpdateData={updateNewUserData}
        />
        <Box
          sx={{
            opacity: !isShowModuleEditor ? "0.4" : "1",
            pointerEvents: !isShowModuleEditor ? "none" : "cursor"
          }}
        >
          <ModuleDetailsSection
            moduleList={edituserPayload.submoduleList}
            onUpdateData={updateNewUserData}
            handleUpdatedSubModuleIds={handleUpdatedSubModuleIds}
          />
        </Box>
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          height: 73,
          width: "calc(100% + 24px)",
          left: "-24px",
          border: "1px solid #CCC2DC",
          px: 4,
          py: 2,
          display: "flex",
          flexDirection: "row-reverse",
          gap: 2,
          background: theme.palette.custom_field_background
        }}
      >
        <Button
          variant="contained"
          sx={{ borderRadius: "100px" }}
          onClick={() => debouncedHandleUpdateUser()}
        >
          {messages.Save}
        </Button>
        <Button
          variant="outlined"
          onClick={() => navigate(NAV_ROUTES.USER_MANAGEMENT)}
          sx={{
            borderRadius: "100px",
            color: theme.palette.custom_button.primary_font,
            borderColor: theme.palette.custom_button.primary_font,
            '&:hover': {
              borderColor: theme.palette.custom_button.primary_font,
            }
          }}
        >
          {messages.Cancel}
        </Button>
      </Box>
    </Container>
  );
};

export default UserDetails;
